import {useState} from 'react'
import {useEnvironment, useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import {Status} from '@wix/ambassador-table-reservations-v1-reservation/types'

import {useReservationConfirmationStorage} from '../storage'
import {getReservationLocationById} from '../../../utils/reservationLocation'
import {useGetLayoutSize} from '../../../components-shared/LayoutSizeProvider'
import {getShouldShowAutoApproveText} from '../../../utils/getShouldShowAutoApproveText'
import {RequestStatus} from '../../../utils/wrapRequest'
import {EXPERIMENTS} from '../../../utils/constants'
import {usePaymentRuleData} from '../../../utils/usePaymentRuleData'

import {getCalendarHref, getMessageType} from './utils'

export const useHooks = () => {
  const {
    reservation,
    reservationLocations,
    orderDetails,
    goToNewReservation,
    regionalSettings,
    approvalTextEditorState,
    isManualApproval,
    getReservationStatus,
    breadcrumbsConfig,
  } = useReservationConfirmationStorage()

  const {experiments} = useExperiments()
  const shouldShowNotFoundErrorText = experiments.enabled(EXPERIMENTS.shouldShowNotFoundErrorText)

  if (reservation?.status === Status.PAYMENT_PENDING) {
    reservation.status = isManualApproval ? Status.REQUESTED : Status.RESERVED
  }

  const {isViewer} = useEnvironment()

  const {t} = useTranslation()
  const [isCancelDialogOpened, setIsCancelDialogOpened] = useState<boolean>(false)

  const isLoading = getReservationStatus === RequestStatus.LOADING

  const currentReservationLocation = getReservationLocationById(
    reservation?.details?.reservationLocationId ?? '',
    reservationLocations,
  )

  const openCancelDialog = () => {
    setIsCancelDialogOpened(true)
  }

  const closeCancelDialog = () => {
    setIsCancelDialogOpened(false)
  }

  const {layoutSize} = useGetLayoutSize()

  const shouldShowAutoApproveText = getShouldShowAutoApproveText(
    approvalTextEditorState,
    isManualApproval,
  )

  const shouldShowOrderDetails =
    orderDetails?.lineItems?.[0].quantity &&
    Number(orderDetails.lineItems[0].lineItemPrice?.amount) > 0

  if (reservation) {
    reservation.status = isViewer
      ? reservation.status
      : shouldShowAutoApproveText
      ? Status.RESERVED
      : Status.REQUESTED
  }

  const {feePerGuestWithCurrency} = usePaymentRuleData(
    currentReservationLocation,
    reservation?.details?.partySize ?? 0,
  )

  return {
    t,
    layoutSize,
    isCancelDialogOpened,
    reservation,
    regionalSettings,
    orderDetails,
    shouldShowOrderDetails,
    goToNewReservation,
    openCancelDialog,
    closeCancelDialog,
    calendarHref: getCalendarHref(currentReservationLocation, reservation),
    messageType: getMessageType(reservation?.reservee ?? ''),
    formattedAddress: currentReservationLocation?.location?.address?.formattedAddress ?? '',
    locationName: currentReservationLocation?.location?.name ?? '',
    timeZone: currentReservationLocation?.location?.timeZone,
    phoneNumber: currentReservationLocation?.location?.phone,
    isLoading,
    shouldShowNotFoundErrorText,
    feePerGuestWithCurrency,
    breadcrumbsConfig,
  }
}
